import { Routes, Route } from "react-router-dom";
import React from 'react';
import { Galerie, Projet, Home, About, Contact, PageNotFound } from '../pages/index';

export const AllRoutes = () => {

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/galerie" element={<Galerie />} />
      <Route path="/galerie/:id" element={<Projet />} />
      <Route path="/about" element={<About />} />
      <Route path="/contact" element={<Contact />} />

      <Route path="*" element={<PageNotFound />} />
    </Routes>
  )
}
