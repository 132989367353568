import React, { useEffect, useState } from 'react';
import ProductCard from '../../components/Elements/ProductCart';
import { getDocs, collection } from 'firebase/firestore';
import { db } from '../../config/firebase';


export const Galerie = () => {

  const [projectList, setProjectList] = useState([]);
  const projectsCollectionRef = collection(db, "projets");

  useEffect(() => {

    const getProjectList = async () => {
      try {
        const data = await getDocs(projectsCollectionRef);
        const filteredData = data.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }))
        setProjectList(filteredData);
      } catch (err) {
        console.error(err);

      }
    };
    getProjectList();
  }, [])

  if (!projectList) {
    return <span className="loading loading-spinner loading-md items-center"></span>;
  }

  return (
    <section className='py-6 mb-3'>
      <main>
        <article className='flex flex-col md:flex-row xl:flex-row justify-center items-center'>
          {projectList.map((projet) => (
            <ProductCard key={projet.id} projet={projet} />
          ))}
        </article>
      </main>
    </section>
  )
}
