import { React } from 'react';

export const Hero = () => {
    return (
        <section>
            <div
                className="hero min-h-screen"
                style={{ backgroundImage: "url(assets/images/bg.PNG)", }}>

                <div className="hero-overlay bg-opacity-20"></div>
                <div className="hero-content text-neutral-content text-center">
                    <div className="max-w-md">
                        <h1 className="mb-5 text-3xl font-bold">Architecte d'intérieur <br />& <br />Décorateur</h1>
                        <a href='/Galerie'>
                            <button className="btn btn-primary">Découvrez mon portfolio</button>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    )
}