// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDhMfM7QojYNDRn6bgCI-xTCX0DcqWWFKU",
  authDomain: "site-architecte.firebaseapp.com",
  projectId: "site-architecte",
  storageBucket: "site-architecte.appspot.com",
  messagingSenderId: "180941410900",
  appId: "1:180941410900:web:e8777084ccb4ae762453c1",
  measurementId: "G-2XFCT5TV3V"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth(app)
export const db = getFirestore(app);