import React from 'react';
import logo from '../../assets/logo.png'

export const SideBar = ({ setShow }) => {
    return (
        <div className="fixed inset-y-0 left-0 z-50 transform transition-transform duration-300 ease-in-out shadow-xl">
            <div className="bg-white shadow-lg w-80 h-full">
                <button onClick={() => setShow(false)} className="btn btn-ghost mb-4 justify-center">
                    Fermer X
                </button>
                <nav className='mb-3'>
                    <ul className="menu bg-base-200 min-h-full p-4 space-y-2">
                        <li><a href='/'>Accueil</a></li>
                        <li><a href='/galerie'>Les réalisations</a>
                        </li>
                        <li><a href='/about'>A propos</a></li>
                        <li><a href="/contact">Devis et contact</a></li>
                    </ul>
                </nav>
                <div className='flex flex-col items-center justify-center'>
                    <button className="btn btn-primary rounded-full mb-2">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
                            <path fillRule="evenodd" d="M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z" clipRule="evenodd" />
                        </svg>
                        <a href="tel:+33659817621">06 59 81 76 21</a>
                    </button>
                </div>
            </div>
        </div>
    )
}