import './App.css';
import React from 'react';
import { AllRoutes } from './routes/AllRoutes';
import Header from './components/Layout/Header';
import Footer from './components/Layout/Footer';

function App() {
  return (
    <div className="App">
      <Header />
      <AllRoutes />
      <Footer />
    </div>
  )
}

export default App;
