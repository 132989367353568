import './ProductCart.css'
import React from 'react';
import { useNavigate } from 'react-router-dom';

const ProductCard = ({ projet }) => {

  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/galerie/${projet.id}`);
  }

  return (
    <div className='card bg-base-100 w-96 rounded-xl shadow-xl border-gray-200' onClick={handleClick}>
      <figure>
        <img src={projet.poster} alt={projet.title} />
      </figure>
      <div class="card-body text-center">
        <h5 className="card-title justify-center text-2xl">{projet.title}</h5>
        <p className='text-center'>{projet.city}</p>
      </div>
    </div>
  )
}

export default ProductCard