import './Home.css';
import React from 'react';
import { Hero } from './components/Hero';

export const Home = () => {

  return (
    <section>
      <Hero />
    </section>
  )
}

