import React from 'react';
import { Testimonial } from './components/Testimonial';

export const About = () => {
  return (
    <section>
      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="lg:max-w-lg">
              <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Architecte d'intérieur et Décorateur </h1>
              <p className="mt-6 text-xl leading-8 text-gray-700">
                Depuis mon enfance, la mode, l'architecture, la décoration et le cinéma ont nourri ma créativité.
              </p>
            </div>
          </div>
        </div>
        <div className="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
          <img
            alt="Livret de décoration et projet personnalisée"
            src="/assets/images/Mag-insta-2.webp"
            className="w-[48rem] max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
          />
        </div>
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
              <p>
                En grandissant, mon intérêt pour l'architecture s'est confirmé : je suis parti étudier l'architecture à l'Université Libre de Bruxelle où j'ai découvert ma vocation : l'architecture d'intérieur.
                De retour en Alsace, ma région d'origine, je me suis spécialisée dans la décoration d'intérieur.
                Après 3 années d'expériences en agence, fort de cette expérience, j'ai décidé de créer ma propre entreprise, « lulu », pour donner vie à ma vision et à ma passion pour le design intérieur.
              </p>
              <p className="mt-8">
                À travers mon regard, je m’efforce de transformer vos intérieurs en des espaces uniques qui reflètent votre personnalité et vos aspirations. Je suis convaincue que chaque lieu peut devenir exceptionnel lorsqu'il est conçu avec soin et attention.
              </p>
              <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">Je valorise la simplicité, le minimalisme, les couleurs et le bien-être.</h2>
              <p className="mt-6">
                Un espace de vie ne doit pas seulement suivre les tendances, mais doit aussi incarner l’essence même de ceux qui y vivent. C’est pourquoi je m'engage à écouter attentivement vos besoins et à faire en sorte que votre lieu de vie soit le miroir de votre identité.
                Ensemble, nous créerons un environnement qui non seulement répondra à vos exigences esthétiques et fonctionnelles, mais qui sera également une véritable extension de vous-même.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Testimonial />
    </section>
  )
}
