import React, { useEffect, useState } from 'react';
import { db } from '../../config/firebase';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { GaleryMansionGrid } from './components/GaleryMansionGrid';


export const Projet = () => {

    const { id } = useParams();
    const [projet, setProjet] = useState(null);

    useEffect(() => {

        const getProjectById = async () => {
            try {
                const projectDoc = doc(db, "projets", id);
                const projectData = await getDoc(projectDoc);

                if (projectData.exists()) {
                    setProjet({ id: projectData.id, ...projectData.data() })
                } else {
                    console.log("aucun projet trouvé :-( !");
                }

            } catch (err) {
                console.error(err);
            }
        };
        getProjectById();
    }, [id]);

    const ajouterSautsDeLigne = (texte) => {
        return texte.replace(/\n/g, "<br />"); // Remplace les sauts de ligne par des <br />
    };

    if (!projet) {
        return <span className="loading loading-spinner loading-md mx-auto"></span>;
    };

    console.log(projet.description);

    return (
        <section>
            <main>
                <button className='btn btn-outline my-3'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
                    </svg>
                    <a href="/galerie">Retour</a>
                </button>
                <article className='w-full'>
                    <img src={projet.image} className='w-full' alt={projet.title} />
                </article>

                <article className='flex flex-col mb-3'>
                    <div className='py-4 ml-2'>
                        <h2 className='text-4xl text-accent'>{projet.title}</h2>
                        <span className='mb-3'>{projet.city}</span>

                    </div>
                    <div className='flex flex-col xl:flex-row justify-around w-full gap-2'>
                        <div className='flex flex-col'>
                            <p className='text-xl font-medium mb-3 ml-2'>{projet.text1}</p>
                            <p className='mb-3 ml-2'>{projet.text2}</p>
                            <p className='mb-3 ml-2'>{projet.text3}</p>
                            <p className='mb-3 ml-2'>{projet.text4}</p>
                            <p className='mb-3 ml-2'>{projet.text5}</p>
                        </div>
                        <img
                            src={projet.poster}
                            className='rounded-xl'
                            alt={projet.title}
                            width={400}
                        />
                    </div>
                </article>
                <article className='image-grid'>
                    <GaleryMansionGrid images={projet.images} />
                </article>
            </main>
        </section>
    )
}


